import { useWallet } from "@solana/wallet-adapter-react";
import React, { useMemo, useEffect, useCallback, useState } from "react";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import { styled } from "@mui/system";
import { getParsedNftAccountsByOwner } from "@nfteyez/sol-rayz";

import {
  loadTokenEntanglementProgram,
  swapEntanglement,
} from "../utils/entangler";
import { ensureAtaExists } from "../utils/ensureAtaExists";
import { useWalletModal } from "../contexts/WalletContext";
import { useConnection } from "../contexts/ConnectionContext";
import mintList from "../utils/mint-list.json";

const SwapRoot = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  flexDirection: "column",
  flex: "1 1 auto",
  width: "100%",
});

const SwapBox = styled("div")({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  flexDirection: "row",
  flexWrap: "wrap",
  gridGap: "50px 20px",
  margin: "30px 0px 50px 0px",
  maxWidth: 1800,
  width: "100%",
  padding: "0px 10px",
  // background: "red",
});

const SwapCard = styled("div")({
  border: "2px solid #333333",
  boxShadow: "0px 0px 50px rgba(0,0,0,0.5)",
  background: "#2a2a2a",
  padding: "40px 20px",
  borderRadius: 10,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  gridGap: 20,
  position: "relative",
  maxWidth: 350,
  width: "100%",
});

const ItemInfo = styled("div")({
  fontSize: 12,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gridGap: 10,
});

const InfoTitle = styled("span")({
  color: "#5b6ee1",
});

const NftImage = styled("img")({
  width: 200,
  height: 200,
  background: "#000",
});

const Placeholder = styled("div")({
  width: 200,
  height: 200,
  background: "#000",
});

const Title = styled("h1")({
  color: "#5b6ee1",
  fontWeight: "bold",
  fontSize: "3rem",
  textShadow: "2px 2px 5px #000",
});

const NgmiBox = styled("div")({
  // background: "#2a2a2a",
  background: "#2a2a2a",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  gridGap: "10px",
  padding: "50px 30px",
  border: "2px solid #333333",
  boxShadow: "0px 0px 50px rgba(0,0,0,0.5)",
  borderRadius: 10,
  margin: "0px 20px 50px 20px",
  maxWidth: 1800,
});

const Title2 = styled("h1")({
  color: "#5b6ee1",
  fontWeight: "bold",
  fontSize: "2rem",
  display: "block",
});

const About = styled("p")({
  color: "#2a2a2a",
  maxWidth: 880,
  textAlign: "center",
  background: "#fff",
  padding: 10,
  border: "2px solid #333333",
  boxShadow: "0px 0px 50px rgba(0,0,0,0.5)",
  borderRadius: 10,
});

const OldTag = styled("div")({
  position: "absolute",
  top: -16,
  left: "50%",
  transform: "translateX(-50%)",
  padding: "5px 10px",
  background: "#dc3545",
  borderRadius: 5,
});

const NewTag = styled("div")({
  position: "absolute",
  top: -16,
  left: "50%",
  transform: "translateX(-50%)",
  padding: "5px 10px",
  background: "#28a745",
  borderRadius: 5,
});

const allMintAddresses = mintList.flat();

export function Swap() {
  const connection = useConnection();
  const wallet = useWallet();
  const [imageMap, setImageMap] = useState({});
  const [metaMap, setMetaMap] = useState({});
  const [loading, setLoading] = useState(false);
  const { setVisible } = useWalletModal();
  const [matchingNfts, setMatchingNfts] = useState<any>([]);
  const sortedNfts = useMemo(() => {
    if (!matchingNfts?.length || !Object.keys(metaMap).length) return null;
    const sortedItems = matchingNfts.sort((a, b) => {
      if (!metaMap[a.mint]?.attributes || !metaMap[b.mint]?.attributes) {
        return 0;
      }
      const metaA = metaMap[a.mint].attributes.find(
        (x) => x.trait_type === "Mint #"
      ).value;
      const metaB = metaMap[b.mint].attributes.find(
        (x) => x.trait_type === "Mint #"
      ).value;

      return Number(metaA) - Number(metaB);
    });

    return sortedItems;
  }, [matchingNfts, metaMap]);

  const anchorWallet = useAnchorWallet();

  const loadProgram = useCallback(async () => {
    if (!anchorWallet) return;
    await loadTokenEntanglementProgram(anchorWallet, connection);
  }, [anchorWallet, connection]);

  useEffect(() => {
    loadProgram();
  }, [loadProgram]);

  const updateNfts = useCallback(async () => {
    if (!wallet?.publicKey) return null;
    setLoading(true);
    const nfts = await getParsedNftAccountsByOwner({
      publicAddress: wallet?.publicKey.toBase58(),
      connection,
    });
    const nextMatchingNfts = (nfts || []).filter((nft) =>
      allMintAddresses.includes(nft.mint)
    );

    setMatchingNfts(nextMatchingNfts);
    setLoading(false);
  }, [connection, wallet?.publicKey]);

  useEffect(() => {
    updateNfts();
  }, [updateNfts]);

  const handleSubmit = useCallback(
    async ({ mintA, mintB, currentMint }) => {
      if (!anchorWallet) return;
      console.log({ mintA, mintB });
      setLoading(true);

      await ensureAtaExists(anchorWallet, connection, currentMint);

      const txnResult = await swapEntanglement(
        anchorWallet,
        connection,
        mintA,
        mintB,
        ""
      );
      updateNfts();
      console.log("entangledPair", txnResult.epkey);
    },
    [anchorWallet, connection, updateNfts]
  );

  const fetchImages = useCallback(async () => {
    if (matchingNfts?.length) {
      const nextImages = {};
      const nextMeta = {};
      for (const nft of matchingNfts) {
        const response = await fetch(nft.data.uri);
        const data = await response.json();
        nextImages[nft.mint] = data.image;
        nextMeta[nft.mint] = data;
      }
      setMetaMap((state) => ({ ...state, ...nextMeta }));
      setImageMap((state) => ({ ...state, ...nextImages }));
    }
  }, [matchingNfts]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  const noTokensFound = useMemo(() => {
    return !matchingNfts?.length;
  }, [matchingNfts?.length]);

  const renderItem = useCallback(
    (ape) => {
      const pair = mintList.find((addresses) => addresses.includes(ape.mint));
      const isOldToken = pair?.indexOf(ape.mint) === 1;
      const title = metaMap[ape.mint]?.attributes?.find(
        (x: any) => x?.trait_type === "Title"
      )?.value;
      const mintNumber = metaMap[ape.mint]?.attributes?.find(
        (x: any) => x?.trait_type === "Mint #"
      )?.value;
      return (
        <SwapCard key={ape.mint}>
          {/* <NftImage src={"/solarians-example.gif"} alt="ape" /> */}
          {imageMap[ape.mint] ? (
            <NftImage src={imageMap[ape.mint]} alt="ape" />
          ) : (
            <Placeholder />
          )}
          <ItemInfo>
            <div>
              <InfoTitle>Name:</InfoTitle> {metaMap[ape.mint]?.name}
            </div>
            <div>
              <InfoTitle>Title:</InfoTitle> {title}
            </div>
            <div>
              <InfoTitle>Solarian #:</InfoTitle> {mintNumber}
            </div>
          </ItemInfo>
          <Button
            variant="contained"
            onClick={async () => {
              if (!pair) return;
              const [mintA, mintB] = pair;
              await handleSubmit({ mintA, mintB, currentMint: ape.mint });
            }}
          >
            {isOldToken ? "Swap for New Token" : "Swap for Old Token"}
          </Button>
          {isOldToken ? <OldTag>Original</OldTag> : <NewTag>New</NewTag>}
        </SwapCard>
      );
    },
    [handleSubmit, imageMap, metaMap]
  );

  return (
    <SwapRoot>
      <Title>Solarians Swap</Title>
      {!loading && !!wallet?.connected && (
        <About>
          Swapping is free. You can swap back and forth as many times as you'd
          like as long as you own the token. There may be two transactions you
          have to approve.
        </About>
      )}
      <SwapBox>
        {loading && <CircularProgress />}
        {!loading && !wallet?.connected && (
          <Button variant="contained" onClick={() => setVisible(true)}>
            Connect Wallet
          </Button>
        )}
        {!loading && !!wallet?.connected && (
          <>{(sortedNfts || []).map(renderItem)}</>
        )}
        {!loading && !!wallet?.connected && noTokensFound && (
          <NgmiBox>
            <Title2>NGMI</Title2>
            <Button
              variant="outlined"
              target="_blank"
              rel="noopener noreferrer"
              href="https://tensor.trade/trade/solarians"
            >
              Redeem yourself
            </Button>
          </NgmiBox>
        )}
      </SwapBox>
    </SwapRoot>
  );
}
