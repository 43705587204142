import React from "react";

import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import TwitterIcon from "@mui/icons-material/Twitter";
import ChatIcon from "@mui/icons-material/Chat";

import { Settings } from "./Settings";
import imageLogo from "../images/solarians-logo.gif";

const HeaderRoot = styled("div")({
  padding: 8,
  borderRadius: 4,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const SocialLinks = styled("div")({
  display: "flex",
  gridGap: 5,
});

const Logo = styled("img")({
  width: 100,
  height: 100,
});

const LogoContainer = styled("div")({
  flex: "1 1 auto",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export default function Header() {
  return (
    <HeaderRoot>
      <SocialLinks>
        <Button
          href="https://discord.gg/solarians"
          target="_blank"
          rel="noreferrer"
          variant="contained"
        >
          <ChatIcon />
        </Button>
        <Button
          href="https://twitter.com/SolariansNFT"
          target="_blank"
          rel="noreferrer"
          variant="contained"
        >
          <TwitterIcon />
        </Button>
      </SocialLinks>

      <a href="https://www.solarians.click">
  <LogoContainer>
    <Logo src={imageLogo} />
  </LogoContainer>
</a>

      <div>
        <Button style={{ visibility: "hidden" }} disabled></Button>
        <Settings narrow={true} />
      </div>
    </HeaderRoot>
  );
}
